import React from "react";
import { Route, Routes, BrowserRouter as Router } from "react-router-dom";

import Header from "./Components/Header";
import Home from "./Components/Home";
import Cart from "./Components/Cart";
import Courses from "./Components/Courses";
import Contact from "./Components/Contact";
import Footer from "./Components/Footer";
import Modal from "./Components/Modal";

import { useGlobalContext } from "./context";
import PrivacyPolicy from "./Components/PrivacyPolicy";
import TNC from "./Components/TNC";

const App = () => {
  const { modal } = useGlobalContext();
  return (
    <Router>
      {modal.open && <Modal data={modal.tid} />}
      <Header />
      <main className="main-content-area">
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/training" element={<Courses />} />
          <Route exact path="/contact" element={<Contact />} />
          <Route exact path="/cart" element={<Cart />} />
          <Route exact path="/privacypolicy" element={<PrivacyPolicy />} />
          <Route exact path="/tnc" element={<TNC />} />
        </Routes>
      </main>
      <Footer />
    </Router>
  );
};

export default App;
