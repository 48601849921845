import React from "react";

const TNC = () => {
  return (
    <section className="section-container">
      <h1 className="section-heading">Terms {"&"} Conditions</h1>
      <ol className="contents-list">
        <li>
          <h2 className="sub-heading">Cancellation and Refund Policies:</h2>
          <p className="list-content">
            Please note that we have a refund policy of 7 business days. You can
            initiate refund within 2 days of purchasing the course After this
            timeframe, refunds will not be applicable. if you have any problem
            using the web application we would love to solve your issue just
            contact to{" "}
            <a href="mailto:contact@mkcysolutions.com">
              contact@mkcysolutions.com
            </a>{" "}
            or on our WhatsApp Support from 10 am to 10 pm IST (+918279825323)
          </p>
        </li>
        <li>
          <h2 className="sub-heading">Copyright</h2>
          <p className="list-content">
            All material on this website is owned by{" "}
            <strong>
              MKCY Industrial Solutions Pvt Ltd. ALL RIGHTS RESERVED
            </strong>
            . Any other use, including but not limited to the reproduction,
            distribution of the content of the website and courses is strictly
            prohibited unless authorized by{" "}
            <strong>MKCY Industrial Solutions Pvt Ltd.</strong>
          </p>
        </li>
        <li>
          <h2 className="sub-heading">Pricing</h2>
          <p className="list-content">
            The pricing of the courses are visible in the trainings section and
            can go down during flash sale and other occasions. During such
            situations, the prices might fluctuate from the actual prices
            visible on the screen.
          </p>
        </li>
<li>
<h2 className="sub-heading">Shipping and Delivery Information</h2>

<p className="list-content">
<strong>Online Courses</strong><br/>
For our online courses, we offer access to the course materials upon successful payment. As soon as your payment is confirmed, you will receive an email containing your login credentials and instructions on how to access the course platform. You can then log in and start your learning journey.<br/>
<br/>Please ensure that you provide accurate contact information during the checkout process to ensure the seamless delivery of course access details. If you encounter any issues accessing your course, please don’t hesitate to contact our support team.
<br/><br/>We’re here to help you have a smooth and rewarding learning experience.
</p>
</li>
      </ol>
      <h3 className="sub-heading">
        <em>
          The Website Policies and Terms {"&"} Conditions may be changed or
          updated occasionally to meet the requirements and standards. Therefore
          the Customers' are encouraged to frequently visit these sections in
          order to be updated about the changes on the website. Modifications
          will be effective on the day they are posted.
        </em>
      </h3>
    </section>
  );
};

export default TNC;
