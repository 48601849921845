import React, { useContext, useState } from "react";
import { useEffect } from "react";
import trainings from "./assets/data/courses";

const AppContext = React.createContext();

const AppProvider = ({ children }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [modal, setModal] = useState({ open: false, details: {} });
  const [cartItems, setCartItems] = useState([]);
  const [checkout, setCheckout] = useState(false);
  if (modal.open) {
    document.getElementsByTagName("body")[0].style.overflowY = "hidden";
  } else {
    document.getElementsByTagName("body")[0].style.overflowY = "scroll";
  }

  document.onkeydown = (e) => {
    if (e.key === "Escape" && modal.open) {
      setModal({ open: false, details: {} });
    }
  };

  document.onclick = (e) => {
    if (e.target.classList.contains("model-overlay")) {
      setModal({ open: false, details: {} });
    }
  };

  const handleAddBtnClick = (e, course) => {
    if (!cartItems.includes(course.id)) {
      e.target.classList.add("red");
      setCartItems((p) => [...p, course.id]);
    } else {
      e.target.classList.remove("red");
      setCartItems((p) => p.filter((a) => a !== course.id));
    }
  };

  const getItemsTotal = () => {
    let total = 0;
    cartItems.forEach((id) => {
      const item = trainings.filter((a) => a.id === id)[0];
      total += Math.floor((item.price * item.discountRate) / 100);
    });
    return total;
  };

  useEffect(() => {
    if (checkout) {
      window.scrollTo(0, 500);
    } else {
      window.scrollTo(0, 0);
    }
  }, [checkout]);

  return (
    <AppContext.Provider
      value={{
        isMenuOpen,
        setIsMenuOpen,
        modal,
        setModal,
        cartItems,
        checkout,
        setCheckout: setCheckout,
        setCartItems: setCartItems,
        handleAddBtnClick: handleAddBtnClick,
        getItemsTotal: getItemsTotal,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

const useGlobalContext = () => {
  return useContext(AppContext);
};

export { AppProvider, useGlobalContext };
