import React from "react";

import "../css/Policies.css";

const PrivacyPolicy = () => {
  return (
    <section className="section-container">
      <h1 className="section-heading">Privacy Policy</h1>

      <p className="list-content">
        Thank you for joining the Skillvyuh. We at Skillvyuh respect your
        privacy and want you to understand how we collect, use, and share data
        about you. This Privacy Policy covers our data collection practices and
        describes your rights to access, correct, or restrict our use of your
        personal data.
      </p>
      <br />
      <p className="list-content">
        <strong>
          Unless we link to a different policy or state otherwise, this Privacy
          Policy applies when you visit or use the Skillvyuh web application,
          APIs, or related services (the "Services").
        </strong>
      </p>
      <div className="contents-list-container">
        <h2 className="sub-heading">Table of Contents</h2>
        <ol className="contents-list">
          <li className="content-list-item">
            <a href="#data-collection">What data we collect</a>
          </li>
          <li className="content-list-item">
            <a href="#data-acquisition">How we get data about you</a>
          </li>
          <li className="content-list-item">
            <a href="#data-usage">How we use your data</a>
          </li>
          <li className="content-list-item">
            <a href="#data-sharing">Who we share your data with</a>
          </li>
          <li className="content-list-item">
            <a href="#security">Security</a>
          </li>
          <li className="content-list-item">
            <a href="#your-rights">Your Rights</a>
          </li>
        </ol>
      </div>

      <section id="data-collection">
        <h3 className="sub-heading">1. What data we collect</h3>
        <p className="list-content">
          We collect certain data from you directly, like information you enter
          yourself, data about your participation in courses, and data from
          third-party platforms you connect with Skillvyuh. We also collect some
          data automatically, like information about your device and what parts
          of our Services you interact with or spend time using.
        </p>
        <div className="sub-list">
          <h4 className="sub-heading">1.1 Data you provide to Us</h4>
          <p className="list-content">
            We may collect different data from or about you depending on how you
            use the Services. Below are some examples to help you better
            understand the data we collect.
          </p>
          <ul className="sub-list">
            <li>
              <strong>Account Data: </strong>
              <p className="list-content">
                In order to use certain features (like enrolling in a course),
                you need to create a user account. When you create or update
                your account, we collect and store the data you provide, like
                your email address, password, and assign you a unique
                identifying number ("Account Data").
              </p>
            </li>
            <li>
              <strong>Profile Data: </strong>
              <p className="list-content">
                You can also choose to provide profile information like a photo.
              </p>
            </li>
            <li>
              <strong>Shared Content: </strong>
              <p className="list-content">
                Parts of the Services let you interact with other users or share
                content publicly, including by posting reviews on a course page,
                asking or answering questions, sending messages to students or
                instructors, or posting photos or other work you upload. Such
                shared content may be publicly viewable by others depending on
                where it is posted.
              </p>
            </li>
            <li>
              <strong>Course Data: </strong>
              <p className="list-content">
                When you enroll in and take courses, we collect certain data
                including which courses, assignments and quizzes you've started
                and completed; your exchanges with instructors, teaching
                assistants, and other students; and essays, answers to
                questions, and other items submitted to satisfy course
                requirements.
              </p>
            </li>
            <li>
              <strong>Student Payment Data: </strong>
              <p className="list-content">
                If you make purchases, we collect certain data about your
                purchase (such as your email and contact number ) as necessary
                to process your order. You must provide certain payment and
                billing data directly to our payment processing partners,
                including your name, credit card information, billing address,
                and zip code. For security, Skillvyuh does not collect or store
                sensitive cardholder data, such as full credit card numbers or
                card authentication data.
              </p>
            </li>
            <li>
              <strong>Communications and Support: </strong>
              <p className="list-content">
                If you contact us for support or to report a problem or concern
                (regardless of whether you have created an account), we collect
                and store your contact information, messages, and other data
                about you like your name, email address, location, operating
                system, IP address, and any other data you provide or that we
                collect through automated means (which we cover below). We use
                this data to respond to you and research your question or
                concern, in accordance with this Privacy Policy.
              </p>
            </li>
          </ul>
          <p className="list-content">
            When you create an account and use the Services, including through a
            third-party platform, we collect any data you provide directly,
            including:
          </p>
          <ul className="sub-list">
            <li>
              <strong>System Data: </strong>
              <p className="list-content">
                Technical data about your device, like your device type, like
                your IP address, operating system type and version, unique
                device identifiers in encrypted format (SHA1), browser, browser
                language, domain and other systems data, and platform types
                ("System Data").
              </p>
            </li>
            <li>
              <strong>Usage Data: </strong>
              <p className="list-content">
                Usage statistics about your interactions with the Services,
                including courses accessed, time spent on pages or the Service,
                other data regarding your use of the Services ("Usage Data").
              </p>
            </li>
          </ul>
        </div>

        <div className="sub-list">
          <h4 className="sub-heading">
            1.2 Data we collect through automated means
          </h4>
          <p className="list-content">
            When you access the Services (including browsing courses), we
            collect certain data by automated means, including:
          </p>
          <p className="list-content">
            The data listed above is collected through the use of server log
            files and tracking technologies, as detailed in the "Cookies and
            Data Collection Tools" section below. It is stored by us and
            associated with your account.
          </p>
        </div>
      </section>

      <section id="data-acquisition">
        <h3 className="sub-heading">2. How we get data about you</h3>
        <p className="list-content">
          We use tools like analytics services to gather the data listed above.
          Some of these tools offer you the ability to opt out of data
          collection.
        </p>
      </section>

      <section id="data-usage">
        <h3 className="sub-heading">3. How we use your data</h3>
        <p className="list-content">
          We use your data to do things like provide our Services, communicate
          with you, troubleshoot issues, secure against fraud and abuse, improve
          and update our Services, analyze how people use our Services, serve
          personalized advertising, and as required by law or necessary for
          safety and integrity.
        </p>
        <ol className="sub-list" style={{ marginLeft: "1.5rem" }}>
          <li>
            Provide and administer the Services, including to display customized
            content and facilitate communication with other users
          </li>
          <li>
            Process your requests, specific services, information, or features
          </li>

          <li>
            Communicate with you about your account by:
            <ul style={{ marginLeft: "1rem" }}>
              <li>Responding to your questions and concerns</li>
              <li>
                Sending you administrative messages and information, including
                messages from instructors and teaching assistants, notifications
                about changes to our Service, and updates to our agreements
              </li>
              <li>
                Sending you information and in-app messages about new services,
                new features, and other available courses (which you can opt out
                of at any time)
              </li>
              <li>
                Sending push notifications to your wireless device to provide
                updates and other relevant messages (which you can manage from
                the "options" or "settings" page of the mobile app)
              </li>
            </ul>
          </li>
          <li>Manage your account preferences</li>
          <li>
            Facilitate the Services' technical functioning, including
            troubleshooting and resolving issues, securing the Services, and
            preventing fraud and abuse
          </li>
          <li>Solicit feedback from users</li>
          <li>Identify unique users across devices</li>
          <li>
            Improve our Services and develop new products, services, and
            features
          </li>
          <li>
            Analyze trends and traffic, track purchases, and track usage data
          </li>
          <li>As required or permitted by law or</li>
          <li>
            As we, in our sole discretion, otherwise determine to be necessary
            to ensure the safety or integrity of our users, employees, third
            parties, the public, or our Services
          </li>
        </ol>
      </section>

      <section id="data-sharing">
        <h3 className="sub-heading">4. Who we share your data with</h3>
        <p className="list-content">
          We share certain data about you with instructors, other students,
          companies performing services for us, analytics and data enrichment
          providers. We may also share your data as needed for security, legal
          compliance, or as part of a corporate restructuring.
        </p>
        <p className="list-content">
          We may share your data with third parties under the following
          circumstances or as otherwise described in this Privacy Policy:
        </p>
        <ol style={{ marginLeft: "1.5rem" }}>
          <li>
            <strong>With Your Instructors:</strong> We share data that we have
            about you (except your email address) with instructors or teaching
            assistants for courses you enroll in or request information about,
            so they can improve their courses for you and other students. This
            data may include things like your country, browser language,
            operating system, device settings. We will not share your email
            address with instructors or teaching assistants.
          </li>
          <li>
            <strong>With Service Providers, Contractors, and Agents:</strong> We
            share your data with third-party companies who perform services on
            our behalf, like payment processing, data analysis.
          </li>
          <li>
            <strong>For Security and Legal Compliance:</strong> We may disclose
            your data to third parties if we (in our sole discretion) have a
            good faith belief that the disclosure is:
            <ul style={{ marginLeft: "1rem" }}>
              <li>Permitted or required by law</li>
              <li>
                Requested as part of a judicial, governmental, or legal inquiry,
                order, or proceeding
              </li>
              <li>
                Reasonably necessary as part of a valid subpoena, warrant, or
                other legally-valid request
              </li>
              <li>
                Reasonably necessary to enforce our Terms of Use, Privacy
                Policy, and other legal agreements
              </li>
              <li>
                Required to detect, prevent, or address fraud, abuse, misuse,
                potential violations of law (or rule or regulation), or security
                or technical issues
              </li>
            </ul>
          </li>
        </ol>
      </section>

      <section id="security">
        <h3 className="sub-heading">5. Security</h3>
        <p className="list-content">
          We use appropriate security based on the type and sensitivity of data
          being stored. As with any internet-enabled system, there is always a
          risk of unauthorized access, so it's important to protect your
          password and to contact us if you suspect any unauthorized access to
          your account.
        </p>{" "}
        <br />
        <p className="list-content">
          Skillvyuh takes appropriate security measures to protect against
          unauthorized access, alteration, disclosure, or destruction of your
          personal data that we collect and store. These measures vary based on
          the type and sensitivity of the data. Your password is an important
          part of our security system, and it is your responsibility to protect
          it. You should not share your password with any third party, and if
          you believe your password or account has been compromised, you should
          change it immediately and contact us with any concerns.
        </p>
      </section>

      <section id="your-rights">
        <h3 className="sub-heading">6. Your Rights</h3>
        <p className="list-content">
          You have certain rights around the use of your data. You can update or
          terminate your account from within our Services, and can also contact
          us for individual rights requests about your personal data. Parents
          who believe we've unintentionally collected personal data about their
          underage child should contact us for help deleting that information.
        </p>
      </section>
    </section>
  );
};

export default PrivacyPolicy;
