const trainings = [
  {
    id: "lx101",
    title: "Linux 101 for Beginners",
    courseDetails:
      "The Linux 101 for Beginners training provides you a jumpstart into Linux. Whether you have been a day-to-day Linux user or a complete beginner, the Linux 101 for Beginners training by SkillVyuh will give you an upper edge, be it in your life as a student or in your career! Click on ENROLL to know more.",
    img: require("../images/linux101.png"),
    price: 1399.0,
    discountRate: 50.0,
  },
  {
    id: "ns101",
    title: "Network Security 101",
    courseDetails:
      "The Network Security 101 training provides you a complete package of modules where you get to learn about the bits and pieces of Network Security. In this training, you will understand the very basics of networking in detail followed by stepping and escalating your career into Network Security. The Network Security 101 training by SkillVyuh has been designed so that both students and working professionals can easily understand the concepts served in our training to aid you a detailed outlook into Network Security! Click on ENROLL to know more.",
    img: require("../images/ns101.png"),
    price: 1399.0,
    discountRate: 50.0,
  },
  {
    id: "pypro",
    title: "Python Programming",
    courseDetails:
      "Our Python Programming Training covers essential concepts for beginners and experienced programmers. With hands-on training, you'll gain real-world experience and learn to build efficient and scalable applications in Python. Our experienced trainers offer up-to-date and practical training, ensuring you learn the latest techniques. Enroll today to enhance your skills and become an expert in Python programming.",
    img: require("../images/python.png"),
    price: 1399.0,
    discountRate: 50.0,
  },
  {
    id: "apipent",
    title: "API Penetration Testing",
    courseDetails:
      "Our API Penetration Testing training at SkillVyuh equips you with the knowledge and techniques to identify and exploit vulnerabilities in APIs. This program covers the fundamental aspects of API security, including the latest attack vectors and defenses. With experienced professionals as trainers, our course provides practical and up-to-date training for both students and professionals. Enroll today to gain a comprehensive understanding of API security and learn how to secure APIs against potential threats. Don't miss out on this opportunity to enhance your skills in API Penetration Testing.",
    img: require("../images/apipent.png"),
    price: 1399.0,
    discountRate: 50.0,
  },
  {
    id: "wapt",
    title: "Web App Pentesting",
    courseDetails:
      "Our WAPT training at SkillVyuh covers the key concepts and techniques needed to identify and exploit vulnerabilities in web applications. You'll learn about fundamental aspects of web application security, including the latest attack vectors and defenses. Our course is designed for both students and professionals, providing them with the skills necessary to excel in the field of web application penetration testing. Our experienced trainers provide practical and up-to-date training. Enroll in our WAPT program to gain a deep understanding of web application penetration testing and learn how to secure web applications against potential threats",
    img: require("../images/wapt.png"),
    price: 1399.0,
    discountRate: 50.0,
  },
  {
    id: "3dgd",
    title: "3D Graphics Development",
    courseDetails:
      "With the dawn of various new technologies relying on 3D graphics and services like Metaverse, there is a booming need of 3D Graphics Developers. With this training course of SkillVyuh, get a chance to learn from industry experts. In this course you will get to learn about the famous software - Blender.",
    img: require("../images/3dgd.png"),
    price: 1399.0,
    discountRate: 50.0,
  },
];

export default trainings;
