import React from "react";

import "../css/Contact.css";

const Contact = () => {
  return (
    <section className="section-container">
      <h1 className="section-heading purple">Reach out to us!</h1>
      <div className="row-flex">
        <div className="contact-info">
          <p className="contact">
            🤙🏻 <a href="tel:+918279825323">+91 8279825323</a>
          </p>
          <p className="or">or</p>
          <p className="contact">
            ✉{" "}
            <a href="mailto:contact@mkcysolutions.com">
              contact@mkcysolutions.com
            </a>
          </p>
        </div>
        <div className="form-container">
          <form
            action="https://docs.google.com/forms/d/e/1FAIpQLSfhdlh_soGN1WHd234GBmcGUP9E89169lPuTEWyf2hALmb8xg/formResponse"
            method="POST"
            className="contact-form"
          >
            <input
              type="text"
              jsname
              name="entry.640976434"
              id="entry.640976434"
              placeholder="Name"
            />
            <input
              type="email"
              name="entry.428048498"
              id="entry.428048498"
              placeholder="Email"
            />
            <input
              type="tel"
              name="entry.1243773213"
              id="entry.1243773213"
              placeholder="Mobile Number"
            />
            <textarea
              name="entry.719905634"
              id="entry.719905634"
              cols="30"
              rows="10"
              maxLength={500}
              style={{ resize: "none" }}
              placeholder="Your Query"
            ></textarea>
            <button type="submit" className="submit-form-btn">
              Submit
            </button>
          </form>
        </div>
      </div>
    </section>
  );
};

export default Contact;
