import React from "react";

import "../css/Courses.css";

import courses from "../assets/data/courses";
import CourseCard from "./CourseCard";

const Courses = () => {
  return (
    <section className="section-container">
      <h1 className="section-heading green">Trainings</h1>
      <div className="courses-container">
        {courses.map((course) => {
          return <CourseCard course={course} key={course.id} />;
        })}
      </div>
    </section>
  );
};

export default Courses;
