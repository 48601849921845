import React from "react";

import { FaTimes } from "react-icons/fa";
import { useGlobalContext } from "../context";

import "../css/Modal.css";

const Modal = () => {
  const { modal, setModal, cartItems, handleAddBtnClick } = useGlobalContext();

  return (
    <div className="model-overlay">
      <div className="modal">
        <button
          className="modal-close-btn"
          onClick={() => {
            setModal({ open: false, tid: null });
          }}
        >
          <FaTimes />
        </button>
        <div className="modal-content">
          <h3 className="card-title">{modal.details.title}</h3>
          <p className="course-details">{modal.details.courseDetails}</p>
        </div>
        <button
          className={
            cartItems.includes(modal.details.id)
              ? "remove-from-cart-btn"
              : "add-to-cart-btn"
          }
          onClick={(e) => {
            handleAddBtnClick(e, modal.details);
          }}
        >
          {cartItems.includes(modal.details.id)
            ? "remove from cart"
            : "add to cart"}
        </button>
      </div>
    </div>
  );
};

export default Modal;
